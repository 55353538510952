.custom-radio-btn-group {
    .custom-control {
        margin: 0;
        padding: 0;
        .custom-control-input {
            & + .btn-outline-light {
                color: $gray-600;
            }
            &:checked {
                & + .btn-outline-light {
                    border-color: $primary;
                    background-color: $primary;
                    color: #FFF;
                }
            }
        }
        .btn-outline-light {
            border-color: $gray-300;
        }
    }
}
