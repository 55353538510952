@import "./includes/_fonts.scss";
// @import "./includes/_top.scss";

//sprite
@import "./includes/sprite-mixin";
@import "./includes/sprite";
@include sprites($spritesheet-sprites);

@import '../src/bootstrap/scss/bootstrap.scss';
@import '../src/font-awesome/scss/font-awesome.scss';
@import '../src/slick-carousel/slick/slick.scss';
@import '../src/fancybox-master/dist/jquery.fancybox.css';

// project styles
@import "./includes/_project.scss";

// project resposive styles 
@import "./includes/_responsive.scss";
