// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$account-name: 'account';
$account-x: 0px;
$account-y: 0px;
$account-offset-x: 0px;
$account-offset-y: 0px;
$account-width: 22px;
$account-height: 25px;
$account-total-width: 42px;
$account-total-height: 38px;
$account-image: '../images/sprite.png';
$account: (0px, 0px, 0px, 0px, 22px, 25px, 42px, 38px, '../images/sprite.png', 'account', );
$search-name: 'search';
$search-x: 22px;
$search-y: 0px;
$search-offset-x: -22px;
$search-offset-y: 0px;
$search-width: 20px;
$search-height: 20px;
$search-total-width: 42px;
$search-total-height: 38px;
$search-image: '../images/sprite.png';
$search: (22px, 0px, -22px, 0px, 20px, 20px, 42px, 38px, '../images/sprite.png', 'search', );
$tick-black-name: 'tick-black';
$tick-black-x: 0px;
$tick-black-y: 25px;
$tick-black-offset-x: 0px;
$tick-black-offset-y: -25px;
$tick-black-width: 18px;
$tick-black-height: 13px;
$tick-black-total-width: 42px;
$tick-black-total-height: 38px;
$tick-black-image: '../images/sprite.png';
$tick-black: (0px, 25px, 0px, -25px, 18px, 13px, 42px, 38px, '../images/sprite.png', 'tick-black', );
$tick-white-name: 'tick-white';
$tick-white-x: 18px;
$tick-white-y: 25px;
$tick-white-offset-x: -18px;
$tick-white-offset-y: -25px;
$tick-white-width: 18px;
$tick-white-height: 13px;
$tick-white-total-width: 42px;
$tick-white-total-height: 38px;
$tick-white-image: '../images/sprite.png';
$tick-white: (18px, 25px, -18px, -25px, 18px, 13px, 42px, 38px, '../images/sprite.png', 'tick-white', );
$spritesheet-width: 42px;
$spritesheet-height: 38px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($account, $search, $tick-black, $tick-white, );
$spritesheet: (42px, 38px, '../images/sprite.png', $spritesheet-sprites, );


