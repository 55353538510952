@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/SegoeUIRegular/SegoeUIRegular.eot");
  src: local('Segoe UI'), url("../fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIRegular/SegoeUIRegular.woff") format("woff"), url("../fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot");
  src: local('Segoe UI Semibold'), url("../fonts/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUISemiBold/SegoeUISemiBold.woff") format("woff"), url("../fonts/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/SegoeUIBold/SegoeUIBold.eot");
  src: local('Segoe UI Bold'), url("../fonts/SegoeUIBold/SegoeUIBold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIBold/SegoeUIBold.woff") format("woff"), url("../fonts/SegoeUIBold/SegoeUIBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/SegoeUILight/SegoeUILight.eot");
  src: local('Segoe UI Light'), url("../fonts/SegoeUILight/SegoeUILight.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUILight/SegoeUILight.woff") format("woff"), url("../fonts/SegoeUILight/SegoeUILight.ttf") format("truetype");
}

@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/SegoeUIItalic/SegoeUIItalic.eot");
  src: local('Segoe UI Italic'), url("../fonts/SegoeUIItalic/SegoeUIItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUIItalic/SegoeUIItalic.woff") format("woff"), url("../fonts/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype");
}